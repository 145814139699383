exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-main-alpina-js": () => import("./../../../src/pages/main/alpina.js" /* webpackChunkName: "component---src-pages-main-alpina-js" */),
  "component---src-pages-main-bmw-js": () => import("./../../../src/pages/main/bmw.js" /* webpackChunkName: "component---src-pages-main-bmw-js" */),
  "component---src-pages-main-bmwrawdata-js": () => import("./../../../src/pages/main/bmwrawdata.js" /* webpackChunkName: "component---src-pages-main-bmwrawdata-js" */),
  "component---src-pages-main-dummy-js": () => import("./../../../src/pages/main/dummy.js" /* webpackChunkName: "component---src-pages-main-dummy-js" */),
  "component---src-pages-main-embedtest-js": () => import("./../../../src/pages/main/embedtest.js" /* webpackChunkName: "component---src-pages-main-embedtest-js" */),
  "component---src-pages-main-finnair-js": () => import("./../../../src/pages/main/finnair.js" /* webpackChunkName: "component---src-pages-main-finnair-js" */),
  "component---src-pages-main-fiskars-js": () => import("./../../../src/pages/main/fiskars.js" /* webpackChunkName: "component---src-pages-main-fiskars-js" */),
  "component---src-pages-main-index-js": () => import("./../../../src/pages/main/index.js" /* webpackChunkName: "component---src-pages-main-index-js" */),
  "component---src-pages-main-index-old-selections-js": () => import("./../../../src/pages/main/indexOldSelections.js" /* webpackChunkName: "component---src-pages-main-index-old-selections-js" */),
  "component---src-pages-main-loreal-js": () => import("./../../../src/pages/main/loreal.js" /* webpackChunkName: "component---src-pages-main-loreal-js" */),
  "component---src-pages-main-luxury-js": () => import("./../../../src/pages/main/luxury.js" /* webpackChunkName: "component---src-pages-main-luxury-js" */),
  "component---src-pages-main-netflix-js": () => import("./../../../src/pages/main/netflix.js" /* webpackChunkName: "component---src-pages-main-netflix-js" */),
  "component---src-pages-main-sta-js": () => import("./../../../src/pages/main/sta.js" /* webpackChunkName: "component---src-pages-main-sta-js" */),
  "component---src-pages-main-trends-2024-js": () => import("./../../../src/pages/main/trends2024.js" /* webpackChunkName: "component---src-pages-main-trends-2024-js" */)
}

